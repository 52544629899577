@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");

@font-face {
  font-family: Avenir-Next;
  src: local(Avenir-Next),
    url("Fonts/Avenir-Next/avenir-next-medium.woff") format("woff");
  /* font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 100%; */
}

.font-avenir {
  font-family: "Avenir-Next", sans-serif;
}

.font-montserrat {
  font-family: "Montserrat", sans-serif;
}

.flip-card {
  background-color: transparent;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card-inner.on {
  transform: rotateY(180deg);
}
.flip-card-inner.onh {
  transform: rotateX(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

@media (min-width: 768px) {
  .pc\:hidden {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .hideable{
    display: none !important;
  }
}

@media (min-width: 768px) {
  .text-xxl {
    font-size: 3.75rem;
    line-height: 1;
  }
}

.w-card {
  width: 2.75rem;
}

.rounded-xlg {
  border-radius: 1rem;
}

.text-1xl {
  font-size: 1.5rem;
  line-height: 2.5rem;
}
